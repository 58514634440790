.ProductAttributes {
  //width: 70%;
  display: flex;
  //padding-left: 20px;
  flex-direction: column;
  justify-content: space-between;
  &__title {
    font-size: 26px;
    font-weight: 400;
    margin-top: -6px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  &__list {
    margin-bottom: 30px !important;
    li {
      width: 40%;
      margin-bottom: 2px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    textarea {
      resize: none;
      margin-top: 5px;
      height: 120px !important;
    }
    p {
      font-size: 16px;
      margin-bottom: 0;
      font-weight: bold;
      span {
        font-weight: 300;
        color: #e50019;
        margin-right: 10px;
      }
    }
  }
  &__price {
    display: flex;
    font-size: 22px;
    margin-bottom: 0;
    align-items: center;
    .product_price {
      margin-left: 0;
      margin-bottom: 0;
    }
  }
  &__barcode {
    display: flex;
    align-self: flex-end;
    align-items: flex-end;
    flex-direction: column;
    span {
      color: #cecece;
    }
  }
  &__half {
    width: 50%;
  }
  &__quantity {
    display: flex;
    font-size: 22px;
    margin-bottom: 0px;
    align-items: center;
    .QuantityInput {
      margin-top: 3px;
      margin-left: 10px;
      padding: 10px 20px;
      &--small {
        width: auto;
      }
    }
  }
  .ProductAttributes {
    &__quantity {
      display: flex;
      margin-right: 10px;
      margin-bottom: 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      span.text {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
      }
      .QuantityInput {
        margin-left: 0;
      }
    }
  }
  .product_price {
    h4 {
      font-weight: 500;
      color: black !important;
    }

    .sale_price {
      color: #fff !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .ProductAttributes {
    padding: 0;
    width: 100%;
    margin-top: 20px;
    &__title {
      margin-bottom: 15px;
      font-size: 22px;
    }
    &__price {
      margin-bottom: 10px;
      font-size: 18px;
    }
    &__quantity {
      font-size: 18px;
    }
    &__buttons_wrapper {
      flex-direction: column;
    }
  }
}
@media only screen and (max-width: 1240px) and (min-width: 768px) {
  .ProductAttributes {
    //width: 45%;
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1240px) {
  .ProductAttributes {
    //width: 45%;
  }
}
