.Footer {
  background-color: #000;
  &__bottom {
    display: flex;
    padding: 30px 0;
    align-items: center;
    justify-content: space-between;
  }
  &__copyright {
    margin: 0;
  }
  &__payments {
    display: flex;
    li {
      display: flex;
      border-radius: 4px;
      align-items: center;
      border: 1px solid #ddd;
      &:not(:last-child) {
        margin-right: 15px;
      }
      img {
        padding: 5px;
        max-width: 50px;
        max-height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .Footer {
    &__bottom {
      display: block;
      padding: 15px 0;
    }
    &__copyright {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
    &__payments {
      display: flex;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1024px) {
  .Footer {
    &__bottom {
      padding: 15px 0;
    }
    &__copyright {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Footer {
    &__bottom {
      padding: 15px 0;
    }
  }
}
