.GeneralForm {
  &--half {
    .GeneralForm {
      &__input {
        width: 50%;
      }
    }
  }
  &__input {
    width: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
    &--disabled {
      opacity: 0.5;
    }
    &--recaptcha {
      display: flex;
      margin: 20px 0;
      justify-content: center;
    }
    input:not([type="checkbox"]) {
      padding: 10px 10px;
      border-radius: 4px;
      &[type="submit"] {
        width: 80%;
        border: none;
        color: white;
        font-size: 14px;
        max-width: 400px;
        font-weight: bold;
        background: #44a049;
        text-transform: uppercase;
      }
    }
  }
  &__text {
    width: 100%;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 15px;
    a {
      color: #1c7cff;
      text-decoration: none !important;
    }
  }
}
.CheckoutInputLabel {
  width: 100%;
  font-size: 14px;
  text-align: left;
  color: #212529;
  font-weight: 500;
  user-select: none;
  margin-bottom: 0;
  span {
    color: #ff0000;
  }
  textarea {
    margin-top: 3px;
  }
  .error {
    border-color: #ff0000;
    text-align: left;
  }
  select {
    margin-top: 3px;
  }
  input {
    margin-left: 0;
    margin-top: 3px;
    position: relative;
    &[type="checkbox"] {
      display: none;
      ~ .helpSpan {
        float: left;
        width: 15px;
        height: 15px;
        border: 1px solid #212529;
        margin-right: 10px;
        margin-top: 3px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 9px;
        display: inline-table;
      }
      &:checked {
        ~ .helpSpan {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjQgNS40Ij48cGF0aCBkPSJNNy4zLjFDNy4yIDAgNy4xIDAgNyAwYy0uMiAwLS4zLjEtLjMuMUwyLjkgNC40LjcgMi42Yy0uMS0uMS0uMi0uMS0uMy0uMS0uMSAwLS4yLjEtLjMuMi0uMi4yLS4xLjUuMS42bDIuNSAyYy4xLjEuMi4xLjMuMS4xIDAgLjItLjEuMy0uMWw0LTQuNWMuMi0uMi4yLS41IDAtLjd6IiBmaWxsPSIjMjEyNTI5Ii8+PC9zdmc+);
        }
      }
    }
    &[type="radio"] {
    }
  }
}
.leftSideForm {
  float: left;
  width: 50%;
  max-width: 400px;
  margin-right: 50px;
}
p.error {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
  color: #ff0000;
}
p.sucess {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  color: #44a049;
}

@media only screen and (max-width: 767px) {
  .leftSideForm {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
