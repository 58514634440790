.ProductsContainer {
  display: flex;
  margin-top: 0;
  flex-wrap: wrap;
  border-left: none;
}

@media only screen and (max-width: 767px) {
  .ProductsContainer {
    margin-top: 15px;
  }
}
