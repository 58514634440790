.MainNavigation {
  box-shadow: 0px 2px 9px 0px #cfcfcf !important;
  &__list {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;

    & > li {
      padding-top: 9px;
      padding-bottom: 10px;
      background: none !important;
      &:hover {
        .MainNavigation {
          &__sub_items {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &:last-child {
        border-right: 0;
        padding-right: 5px;
      }

      &:not(:last-child)::after {
        content: "";
        margin: 0 7px;
      }
    }
    a {
      padding: 0;
      color: #000;
      font-size: 12px;
      font-weight: 400;
      font-weight: bold;
      outline: 0 !important;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      text-decoration: none !important;
      &.active,
      &:hover {
        color: #e50019;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .MainNavigation {
    &__list {
      & > li {
        &:not(:last-child)::after {
          content: "|";
        }
      }
    }
  }
}
