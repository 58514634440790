.TotalCheckout {
  &__box {
    padding: 15px;
    margin-top: 45px;
    border: 1px solid black;
    &__info {
      float: left;
      width: 100%;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid black;
      p {
        float: left;
        width: 100%;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
        span {
          float: right;
        }
      }
    }
    &__total {
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      h4 {
        font-weight: bold;
      }
      h3 {
        font-weight: bold;
        color: #ae2e21;
      }
      input {
        border: 0;
        outline: 0;
        font-size: 15px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        border-radius: 5px;
        background: #44a049;
        padding: 8px 25px 10px;
        text-transform: uppercase;
        color: white !important;
        text-decoration: none !important;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
        &:focus {
          background: #368c3a;
        }
      }
    }
  }
  &__TermsCondition {
    font-size: 14px;
    margin-top: 10px;
    color: #212529;
    font-weight: bold;
    text-align: right;
    a {
      text-decoration: none;
      font-weight: normal;
      color: #212529;
      border-bottom: 1px solid #212529;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
      &:hover {
        text-decoration: none;
        color: #212529;
        border-bottom: 2px solid #212529;
      }
    }
  }
}
