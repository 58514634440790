.search_input {
  position: relative;
  .btn,
  &:after {
    top: 0;
    right: 0;
    z-index: 10;
    content: "";
    width: 40px;
    height: 100%;
    position: absolute;
    background: url("../../../assets/images/search.svg") no-repeat center;
  }
  .btn {
    opacity: 0;
    z-index: 11;
    cursor: pointer;
    text-indent: -9999px;
  }
  input {
    color: black;
    border: none;
    font-size: 12px;
    padding-right: 35px;
    text-transform: uppercase;
  }
}

.Search {
  width: 30%;
  position: relative;
  .SearchError {
    width: 100%;
    bottom: -50px;
    padding: 10px;
    z-index: 123123;
    position: absolute;
    background: white;
    border: 1px solid #ef0101;
  }
}

@media only screen and (max-width: 767px) {
  .Search {
    width: 100%;
    display: none;
    border-radius: 20px;
    border: 1px solid #d8d8d8;
    .SearchError {
      font-size: 14px;
    }
  }
}
