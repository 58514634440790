.UserSettings {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
  justify-content: space-between;
  &__content {
    width: 73%;
    padding: 40px;
    background: white;
  }
}

@media only screen and (max-width: 767px) {
  .UserSettings {
    padding: 20px 0;
    &__content {
      width: 100%;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 1550px) {
  .leftSideForm {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
