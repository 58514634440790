.CartTotals {
  width: 100%;
  padding: 20px;
  border: 1px solid black;
  &__item {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &__header {
    font-size: 25px;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  &__label {
    font-size: 24px;
    font-weight: bold;
  }
  &__value {
    font-size: 24px;
  }
  &__buttons {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
  &__message {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 10px;
    text-align: right;

    &--error {
      @extend .CartTotals__message;
      color: red;
    }
  }
}

@media only screen and (max-width: 767px) {
  .CartTotals {
    padding: 15px;
    &__label {
      font-size: 20px;
    }
    &__value {
      font-size: 20px;
    }
    &__buttons {
      flex-direction: column;
      .NavigationButton {
        margin: 0;
        text-align: center;
        margin-bottom: 15px;
      }
      .GoToCheckoutButton {
        margin: 0;
        text-align: center;
      }
    }
  }
}
