.SectionSlider {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 0;
  &__item {
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex !important;
    img {
      height: 300px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .SectionSlider {
    padding-top: 60px;
    padding-bottom: 0;
    &__item {
      img {
        height: auto;
        max-height: 300px;
        cursor: "pointer";
      }
    }
    .slick-prev {
      top: -20px;
      left: auto;
      right: 35px;
    }
    .slick-next {
      right: 0;
      top: -20px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .SectionSlider {
    padding-top: 20px;
    padding-bottom: 0px;
    &__item {
      img {
        height: auto;
        max-height: 300px;
      }
    }
  }
}
