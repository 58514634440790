.ProductSectionTitle {
  width: 100%;
  &__text {
    font-weight: 300;
    margin-bottom: 0;
    font-size: 1.75rem;
    a {
      color: inherit !important;
      text-decoration: none !important;
    }
  }
  &__more {
    color: #022f50 !important;
    &:after {
      content: "";
      width: 17px;
      height: 17px;
      float: right;
      margin-top: 4px;
      margin-left: 5px;
      background: url("../../../assets/images/arrow-more.svg") no-repeat center;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ProductSectionTitle {
    &__text {
      font-size: 18px;
    }
    &__more {
      font-size: 12px;
      &:after {
        width: 14px;
        height: 14px;
      }
    }
  }
}
