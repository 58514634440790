.ListedProductsList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .ProductItem {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .ListedProductsList {
    margin-top: 15px;
  }
}
