.UserSidebar {
  width: 100%;
  max-width: 800px;  
  padding: 0px;
  background: white;
  margin: 0 auto;
  border: 1px solid black;
  &__list {
    li {
      width: auto;
      float: left;
      margin-right: 10px;
    }
    a {
      width: 100%;
      display: block;
      color: black;
      font-size: 18px;
      padding: 10px 20px;
      &:hover,
      &.active {
      }
    }
  }
  &__logout {
  float: right;
    padding: 0;
    width: 100%;
    border: none;
    display: block;
    color: black;
    font-size: 18px;
    text-align: left;
    padding: 10px 20px;
    background: transparent;
    &:hover {
    }
  }
}
.UserSidebar__list li:last-child {
  float:right;
}

@media only screen and (max-width: 767px) {
  .UserSidebar {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
  }
}
.UserSettings__content .GeneralForm__input input[type="submit"]{
  width: 100%;
}
.UserSettings__content .ResetPasswordButton {
  max-width:  400px;
  width: 100%;
}
.UserSettings__content .GeneralForm__input  {
  text-align: left;
} 
.UserSettings__content .leftSideForm {
  margin-bottom: 0;
}
.UserSettings__content {
  max-width: 800px;
  margin: 30px auto 0 auto;
  background: #fbfbfb !important;
  border: 1px solid black;
  margin-top: -1px;
} 
.UserSidebar__list a.active {
  background: black;
  color: white;
}
.UserSettings__content iframe {
  float: left;
}
.Order__header__item:last-child{
  text-align: right;
}
.UserSettings__content .Orders__container {
  display: flow-root;
}
.UserSettings__content .Order {
  background: white;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid black;
}