.CartItems {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(1, 4fr);
}

@media only screen and (max-width: 767px) {
  .CartItems {
    display: block;
  }
}
