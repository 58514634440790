.UserNavigation {
  display: flex;
  margin-left: 40px !important;
  &__logo {
    margin-right: 4px;
    img {
      width: 20px;
    }
  }
  &__items_total {
    top: -6px;
    right: -10px;
    display: flex;
    padding: 0 5px;
    color: white;
    font-size: 11px;
    position: absolute;
    text-align: center;
    border-radius: 100%;
    align-items: center;
    background: #ef0101;
    justify-content: center;
    color: white !important;
  }
  .login-link {
    margin-top: -2px;
  }
  li {
    position: relative;
    margin-right: 30px;
    display: inline-block;
    &:last-child {
      margin-right: 0;
    }
  }
  .divider {
    color: #ef0101;
    margin: 2px 4px 0;
  }
  button {
    border: none;
    font-size: 14px;
    color: #013b63;
    position: relative;
    outline: 0 !important;
    background: transparent;
    text-transform: uppercase;
    text-decoration: none !important;

    &.active-language {
      border-bottom: 1px solid white;
    }
  }
  a {
    color: white;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    text-decoration: none !important;
    &.active,
    &:hover {
      color: white;
    }
  }
  span {
    display: flex;
    color: inherit;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .UserNavigation {
    display: none;
    &__logo {
      img {
        width: 20px;
      }
    }
    &__items_total {
      left: 10px;
      font-size: 9px;
    }
  }
}
