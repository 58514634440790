.ProductSingle {
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  justify-content: space-between;
}

.ProductMainLoader {
  width: 100%;
}

.ProductSidebarLoader {
  width: 20%;
  box-shadow: 3px 3px 6px #ddd;
}
@media only screen and (max-width: 767px) {
  .ProductSingle {
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    &Main {
      width: 100%;
      padding: 20px;
      flex-direction: column;
      &__thumb {
        width: 100%;
        height: auto;
      }
      &__flag {
        left: unset;
        right: 10px;
      }
    }
  }
  .ProductMainLoader {
    width: 100%;
  }
}

@media only screen and (max-width: 1240px) and (min-width: 768px) {
  .ProductSingle {
    flex-direction: column;
    &Main {
      width: 100%;
      &__thumb {
        width: 50%;
      }
    }
    &Sidebar {
      width: 100%;
      margin-top: 20px;
    }
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1240px) {
  .ProductSingle {
    flex-direction: column;
    &Main {
      width: 100%;
      &__thumb {
        width: 50%;
      }
    }
    &Sidebar {
      width: 100%;
      margin-top: 20px;
    }
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .ProductSingleMain {
    //width: 65%;
    //padding: 15px;
    //flex-direction: column;
    &__thumb {
      width: 100%;
      height: 300px;
      margin-bottom: 20px;
    }
  }
  .ProductAttributes {
    width: 100%;
    &__title {
      font-size: 22px;
      margin-bottom: 15px;
    }
    &__price {
      font-size: 16px;
      margin-bottom: 0px;
    }
    &__quantity {
      font-size: 16px;
      margin-bottom: 15px;
    }
    &__list {
      p {
        font-size: 14px;
      }
    }
    .product_price {
      h4 {
        font-size: 16px;
      }
    }
  }
  .AddToWishlist {
    margin-bottom: 15px;
    &__title {
      font-size: 14px;
    }
  }
  .ProductSingleSidebar {
    width: 30%;
  }
}
