.Logo {
  max-width: 130px;
  margin-right: 60px;
  &--white {
    max-width: 150px;
    margin-top: 30px;
    margin-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .Logo {
    max-width: 90px;
    margin-right: 20px;
    &--white {
      margin-top: 0px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Logo {
    &--white {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}
