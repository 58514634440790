.ApplyCouponButton {
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    background: #ffd101;
    padding: 8px 25px 10px;
    text-transform: uppercase;
    color: black !important;
    text-decoration: none !important;
    border: 0;
    margin-top: 22px;
}
@media only screen and (max-width: 767px) {
    .ApplyCouponButton {
        padding: 8px 15px 10px;
        margin-top: 42px;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
    .ApplyCouponButton {
        margin-top: 43px;
    }
}