.ProductSectionButton {
  border: 0;
  height: 28px;
  margin-top: 0;
  color: black;
  font-size: 11px;
  padding: 2px 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  &--success {
    color: white;
    background: #53d800;
  }
  &--update {
    color: black;
    background: #fff8c1;
  }
  &--default {
    color: black;
    background: transparent;
    border: 1px solid #d8d8d8;
    &:hover {
      color: white;
      background: #53d800;
      border-color: #53d800;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ProductSectionButton {
    font-size: 10px;
  }
}
