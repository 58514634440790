.HomeOffers {
  &__item {
    height: 400px;
    max-width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 767px) {
  .HomeOffers {
    margin-top: 20px;
    &__item {
      height: 200px;
      margin-bottom: 20px;
    }
  }
}
