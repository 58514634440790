.UserSettingsTitle {
  margin-bottom: 25px;
  &__main {
    font-size: 30px;
  }
  &__desc {
    font-size: 18px;
    color: #b3b3b3;
  }
}
