.OrderSingle {
  width: 100%;
  &__content {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, 4fr);
  }
  &__footer {
    margin-top: 30px;
  }
}
