.CardCheckout {
  overflow: hidden;
  &__table {
    float: left;
    width: 100%;
    padding: 20px;
    border: 1px solid;
    &__header {
      float: left;
      width: 100%;
      h6 {
        margin-bottom: 0;
      }
    }
    &__main {
      float: left;
      width: 100%;
    }
    &__item {
      float: left;
      width: 100%;
      margin-top: 15px;
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid black;
      &:first-child {
        border-top: none;
      }
    }
  }
  &__product {
    display: flex;
    &__image {
      float: left;
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      border: 1px solid #f4f4f2;
      background: #fafafa;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      float: left;
      width: calc(100% - 140px);
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h4 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .delete-product {
        float: left;
        font-size: 12px;
        color: #9a9a9a;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
        &:hover {
          color: #ae2e21;
        }
      }
    }
  }
  .CheckoutTitle {
    a {
      padding: 5px 15px;
      border-radius: 5px;
      color: white !important;
      background-color: #ae2e21;
      transition: all 0.3s ease-in-out;
      text-decoration: none !important;
      &:hover {
        background-color: #f00;
      }
    }
  }
}

.CheckoutTitle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  h5 {
    font-size: 18px;
  }
  a {
    color: #f00;
    margin-left: 5px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    text-decoration: underline !important;
    &:hover {
      color: #ae2e21;
    }
  }
}

@media only screen and (max-width: 767px) {
  .CardCheckout {
    &__table {
      &__main {
        height: auto;
      }
      &__cover {
        width: 100%;
      }
    }
    .CheckoutTitle {
      display: block;
      margin-bottom: 0;
      h5 {
        font-size: 17px;
        margin-bottom: 10px;
      }
      a {
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .CardCheckout {
    &__table {
      min-width: 100%;
      &__cover {
        width: 100%;
        overflow-y: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
      }
      &__main {
        height: 523px;
      }
    }
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1024px) {
  .CardCheckout {
    &__table {
      min-width: 700px;
      &__cover {
        width: 100%;
        overflow-y: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}
