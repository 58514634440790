.ContentPage {
  &__content {
    margin-top: 50px;
    table {
      margin-bottom: 20px;
      tr {
        border: 1px solid black;
      }
      td,
      th {
        padding: 6px;
        border-right: 1px solid black;
      }
    }
    p {
      margin-bottom: 20px;
    }
  }
}
