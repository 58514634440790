.SuccessPage {
  .Logo {
    margin-right: 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__success {
    margin-top: 30px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
    }
    p {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
}
