.ProductItem {
  padding: 15px;
  display: flex;
  width: 16.667%;
  min-height: 400px;
  position: relative;
  flex-direction: column;
  border: 1px solid #dddddd;
  border-left: 0;
  border-bottom: 0;
  margin-bottom: 40px;
  &:hover {
    .ProductItem {
      &__cart_button {
        opacity: 1;
      }
    }
  }
  &__content {
    height: 300px;
    position: relative;
  }
  &__thumb {
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }
  &__title {
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    color: white;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    font-weight: 300;
    position: absolute;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    span {
      line-height: 1em;
      margin-bottom: 0;
      font-weight: bold;
    }
  }
  &__info_box {
    z-index: 4;
    width: 100%;
    display: flex;
    padding-top: 0;
    flex-wrap: wrap;
    margin-top: 10px;
    position: relative;
  }
  &__more {
    height: 45px;
    padding: 0 20px;
    margin-top: -30px;
    background: white;
    .ProductItem {
      &__info_left {
        button {
          width: 100%;
          height: 100%;
          border: none;
          display: flex;
          background: transparent;
          justify-content: center;
        }
        img {
          width: 30px;
          height: auto;
        }
      }
      &__info_right {
        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__info_right {
    width: 40%;
    outline: 0 !important;
    background: transparent;
    span {
      font-size: 14px;
      font-weight: 300;
    }
    a {
      font-weight: bold;
      color: #013b63 !important;
      text-decoration: none !important;
    }
  }
  &__add_button {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ProductSectionButton {
      z-index: 4;
      margin-top: 0;
      position: relative;
      &__extras {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }
  &__cart_button {
    opacity: 0;
    width: 100%;
    margin-top: 10px;
    transition: all 0.3s ease-in-out;
    a {
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      border-radius: 30px;
      display: inline-block;
      color: black !important;
      padding: 5px 8px 5px 30px;
      text-transform: uppercase;
      border: 1px solid #d8d8d8;
      text-decoration: none !important;
      &:before {
        content: "";
        width: 15px;
        height: 15px;
        margin-top: 3px;
        margin-right: 5px;
        margin-bottom: -3px;
        display: inline-block;
        background: url("../../../assets/images/cart-black.svg") no-repeat;
        background-size: 15px;
      }
    }
  }
  &__cart_toggle {
    height: 50px;
    background: url("../../../assets/images/add_to_cart.png") no-repeat center;
    background-size: 30px;
  }
}

.ProductItemThumb {
  width: 100%;
  & > span {
    width: 100%;
    height: 100%;
  }
}

.ProductsLoader {
  display: flex;
  flex-wrap: wrap;
  border-left: none;
}

.ProductLoader {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .ProductItem {
    width: 50%;
    padding: 10px;
    min-height: 235px;
    &__info {
      &_box {
        padding-top: 10px;
      }
    }
    &__favorite_text {
      display: none !important;
    }
    &__info_right {
      height: 31px;
    }
    &__more {
      height: 30px;
      padding: 0 10px;
      .ProductItem {
        background: blue;
        &__info_left {
          img {
            width: 22px;
          }
        }
        &__info_right {
          a {
            font-size: 12px;
          }
        }
      }
    }
    &__thumb {
      height: 95px;
      margin-bottom: 10px;
    }
    &__title {
      font-size: 13px;
    }
    &__cart {
      &_toggle {
        height: 31px;
        background-size: 20px;
      }
    }
  }
  .ProductLoader {
    width: 100%;
  }
  .ProductTitleLoader {
    display: none;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .ProductLoader {
    width: 33.33333%;
  }
  .ProductItem {
    width: 33.33333%;
    min-height: 300px;
    &__thumb {
      height: 140px;
    }
    &__info {
      &_left {
        width: 40%;
      }
      &_right {
        width: 60%;
      }
    }
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1024px) {
  .ProductLoader {
    width: 33.33333%;
  }
  .ProductItem {
    width: 33.33333%;
    &__info {
      &_left {
        width: 40%;
      }
      &_right {
        width: 60%;
      }
    }
  }
}
.ProductSectionButton__extras a {
  color: black;
  font-weight: bold;
}
.ProductSectionButton__extras a:before{
  content: "> ";
}
.ProductItem__cart_button a {
  opacity: 0.8;
}
.ProductItem__cart_button a:hover {
  opacity: 1;
  background: #ffd100;
}
