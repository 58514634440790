@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  min-height: 100vh;
  background: #fafafa;
  flex-direction: column;
}

.main_content {
  flex: 1;
  background: white;
  padding-bottom: 20px;
}

.product_price {
  display: flex;
  margin-left: 5px;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
  &--sale {
    h4:not(.sale_price) {
      opacity: 0.5;
      margin-right: 10px;
      text-decoration: line-through;
    }
    .sale_price {
      padding: 4px 10px;
      background: #054d00;
    }
  }
  h4 {
    font-size: 20px;
    color: #43484c;
    font-weight: 600;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1600px) {
  .App {
    .container {
      max-width: 1510px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .UserSettings__content .GeneralForm__input input[type="submit"] {
    font-weight: normal;
    font-size: 13px;
    text-transform: none;
  }
  .CardCheckout__product,
  .CardCheckout__product__image,
  .CartItem__thumb {
    display: none;
  }
  .UserSettings__content .NavigationButton {
    margin-left: 0;
  }
  html body .CartItem__content,
  .UserSidebar,
  .FormModal {
    padding: 0 !important;
  }
  .UserSidebar__logout,
  .UserSidebar__list a {
    padding: 5px;
    font-size: 14px;
  }
  .main_content {
    margin-top: 65px;
  }
  .ProductItem {
    width: 100% !important;
  }
  .ProductSectionButton__extras,
  .ProductItem__info_right span {
    height: 20px;
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left !important;
  font-weight: 300 !important;
}

.ProductItem__overlay,
.FavoriteButton {
  display: none;
}
.MainNavigation {
  border-top: 1px solid #e50019;
  border-bottom: 1px solid #e50019;
  box-shadow: none !important;
}
.ThemeSection {
  padding: 0;
  border: 0;
}
.ProductSectionTitle__more {
  display: none;
}
.ProductItem {
  border: 0 !important;
  padding: 0;
  width: 23%;
  padding: 0;
  margin-right: 1.5%;
}
.ProductsContainer {
  border: 0;
}
.ProductItem__info_right {
  border-top: 0;
  border-bottom: 0;
}

.ThemeSection .ProductSectionTitle {
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 1600px) and (min-width: 1366px) {
  .ProductAttributes,
  html body .ProductSingleMain__thumb {
    width: auto;
    float: left;
  }
}
html body .ProductSingleMain__thumb {
  margin-right: 30px;
}
.ProductSingleMain .ProductAttributes__list {
  width: 300px;
}
.ProductSingleMain .ProductAttributes__half {
  width: 100%;
}
html body .ProductSingleMain .ProductAttributes .product_price h4 {
  font-weight: bold !important;
  margin-bottom: 10px;
}
.ProductAttributes__list p span {
  color: black;
}
html body .ProductSingleMain .ProductAttributes__title {
  margin-bottom: 10px;
}
.ProductSingleMain .ProductAttributes__list li {
  width: 100%;
}
.ProductAttributes__list textarea {
  width: 100%;
}
html body .CartButtons__cart:hover {
  background: #f4f4f4;
}
html body .CartButtons__cart:hover img {
  transform: scale(1.1);
}
html body .ProductSingleMain__thumb {
  height: auto;
}
.ProductItem__info_right span {
  margin-bottom: 5px;
  display: block;
}
.UserNavigation__items_total {
  background: black;
  padding: 2px 3px;
  display: inline-block !important;
}
.UserNavigation__items_total {
  display: inline-block;
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  border-radius: 50%;
  background: #111111 !important;
  color: #ffd100 !important;
  top: -13px;
}
html body .CartTotals__header {
  display: none;
}
html body .CartTotals {
  margin-top: 40px;
  border: 0;
  background: black;
  color: white;
}
html body .CartTotals__buttons:hover {
  opacity: 0.9;
}
html body .CartRelatedProducts {
  padding-top: 30px;
  border-top: 1px solid #ddd;
}
.CheckoutTitle h2 {
  color: black;
  font-size: 30px;
  text-align: center;
}
#pos_payment_type,
.GeneralForm__input input:not([type="checkbox"]),
.GeneralForm__input textarea {
  box-sizing: border-box;
  width: 100%;
  height: 2.25rem;
  padding: 0.5rem;
  border: 0;
  margin: 0 0 1rem;
  font-family: inherit;
  font-size: 0.8125rem;
  color: #252525;
  background-color: #f8f6f5;
  box-shadow: inset 0 1px 3px rgba(10, 10, 10, 0.2);
  border-radius: 0;
  transition:
    box-shadow 0.5s,
    border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: 5px;
}
html body .CardCheckout .CheckoutTitle h5 {
  display: none;
}
html body .CardCheckout .CheckoutTitle a {
  color: black;
  display: block;
  margin-top: 5px;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-appearance: none;
  transition:
    background-color 0.25s ease-out,
    color 0.25s ease-out;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0.6875rem;
  margin: 0 0 1rem 0;
  font-size: 0.8125rem;
  background-color: #ffd100;
  color: #252525 !important;
}
html body .TotalCheckout__box {
  margin-top: 68px;
}
html body .TotalCheckout__TermsCondition a {
  background-color: #ffd100;
}
html body .TotalCheckout__box__total h3 {
  color: black;
  font-weight: bold !important;
}
html body .TotalCheckout__box__info p {
  font-weight: normal !important;
}
html body .CardCheckout__table__header h6 {
  font-weight: bold !important;
}
html body .CheckoutTitle a {
  background: #ffd100;
  color: black;
}
html body .CheckoutTitle a:hover {
  color: black;
  text-decoration: none !important;
}
html body .CardCheckout .CheckoutTitle a:hover {
  background: #ffd101;
  text-decoration: underline !important;
}
.Header__search {
  border-bottom: 1px solid #e2dede;
  background-color: black;
}
.OutOfStock-button {
  background: black;
  color: red;
  text-align: center;
  padding: 15px;
  margin-top: 15px;
}
