.Orders {
  &--none {
    .Orders {
      &__message {
        margin-bottom: 15px;
      }
    }
  }
  &__container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, 4fr);
  }
}

@media only screen and (max-width: 1550px) {
  .Orders {
    &__container {
      grid-template-columns: repeat(2, 6fr);
    }
  }
}

@media only screen and (max-width: 767px) {
  .Orders {
    &__container {
      display: block;
    }
  }
}
