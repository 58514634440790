.Checkout {
  padding: 50px;
  &Title {
    h2 {
      font-size: 35px;
      color: #ff0000;
    }
    p {
      color: #b2aeaf;
      font-size: 18px;
      a {
        color: #b2aeaf;
        font-weight: bold;
        text-decoration: none;
        border-bottom: 1px solid#b2aeaf;
      }
    }
  }
  &__loading {
    width: 100%;
    font-size: 22px;
    margin-bottom: 0;
    text-align: center;
  }
}
.mb6 {
  .GeneralForm__input {
    margin-bottom: 6px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .Checkout {
    padding: 0;
    &Title {
      display: block;
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
