.Header {
  background: white;
  &__search {
    padding: 5px 0;
    background: #e50019;
  }
  &__text {
    p {
      color: white;
      font-size: 12px;
      margin-bottom: 0;
      &:not(:last-child):after {
        content: ",";
        margin-right: 5px;
      }
      &.covid_alert {
        font-weight: bold;
        &--loading {
          height: 15px;
          width: 280px;
          border-radius: 4px;
          background-color: #dddddd;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .Header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    &__text {
      flex-direction: column;
      p {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &.covid_alert {
          max-width: 100%;
          margin-top: 0.6rem !important;
        }
      }
    }
  }
  .main_content {
    padding-top: 62px;
  }
}
