.MobileFixedButton {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 123;
  padding: 10px;
  display: none;
  position: fixed;
  text-align: right;
  background: #e50019;
  transition: all 0.3s ease-in-out;
  &--hidden {
    bottom: -100%;
  }
  a {
    display: flex;
    color: white;
    align-items: center;
    text-decoration: none;
    justify-content: flex-end;
    &:after {
      content: "";
      width: 10px;
      height: 15px;
      margin-left: 5px;
      display: inline-block;
      background: url("../../assets/images/right-arrow.svg") no-repeat center;
      background-size: contain;
    }
  }
}

@media only screen and (max-width: 767px) {
  .MobileFixedButton {
    display: block;
  }
}
