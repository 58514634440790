.CartItem {
  width: 100%;
  padding: 20px;
  position: relative;
  border: 1px solid black;
  &:last-child {
    margin-bottom: 0;
  }
  &__thumb {
    width: 200px;
    height: 150px;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
    }
  }
  &__content {
    flex: 1;
    padding-top: 0;
  }
  &__actions {
    top: 0;
    right: 0;
    width: 20%;
    display: flex;
    padding-top: 10px;
    position: absolute;
    padding-right: 10px;
  }
  &__remove {
    width: 20px;
    height: 20px;
    display: flex;
    cursor: pointer;
    margin-left: auto;
    margin-right: 3px;
    align-items: center;
    &:after {
      content: "";
      width: 20px;
      height: 5px;
      display: block;
      cursor: pointer;
      margin-left: auto;
      background: black;
    }
  }
  &__total {
    margin-top: 0;
    font-size: 17px;
    margin-bottom: 0;
  }
  &__info {
    display: flex;
    font-size: 16px;
    margin-bottom: 10px;
    &--title {
      font-size: 18px;
      margin-bottom: 10px;
    }
    b {
      margin-right: 4px;
    }
  }
  a {
    color: inherit !important;
    text-decoration: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .CartItem {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &__thumb {
      padding: 0;
      width: 100%;
      text-align: center;
      img {
        margin: 0 auto;
        max-height: 140px;
      }
    }
    &__info {
      font-size: 15px;
      &--title {
        font-size: 17px;
      }
    }
    &__total {
      font-size: 16px;
    }
    &__content {
      padding-right: 10px;
    }
    &__actions {
      width: 8%;
      right: 10px;
      padding-right: 0;
    }
  }
}
