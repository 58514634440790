.QuantityInput {
  width: 100%;
  &--small {
    width: 90px;
    height: auto;
    display: flex;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
  }
  &--inline {
    width: auto;
    margin: 0px 5px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  input[type="number"] {
    width: 30px;
    border: none;
    margin: 0 5px;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    background: transparent;
    -moz-appearance: textfield; /* Firefox */
  }
  &__counter {
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 5px;
    background: url("../../assets/images/plus-dark.svg") no-repeat center;
    background-size: 10px;
    &--decrement {
      background-image: url("../../assets/images/minus-dark-icon.svg");
    }
  }
}

@media only screen and (max-width: 767px) {
  .ThemeSection {
    //.QuantityInput {
    //  &--small {
    //    display: block;
    //    position: relative;
    //    input[type="number"] {
    //      margin: 0;
    //      height: 100%;
    //      font-size: 16px;
    //      border-radius: 0;
    //      background: transparent;
    //      width: calc(100% - 19px);
    //      border-right: 1px solid #dce7ed;
    //    }
    //    .QuantityInput {
    //      &__counter {
    //        top: 0;
    //        right: 0;
    //        height: 50%;
    //        border-radius: 0;
    //        position: absolute;
    //        background: transparent url("../../assets/images/plus-dark.svg")
    //          no-repeat center;
    //        background-size: 8px;
    //        &--decrement {
    //          top: auto;
    //          bottom: 0;
    //          border-top: 1px solid #dce7ed;
    //          background-image: url("../../assets/images/minus-dark-icon.svg");
    //        }
    //      }
    //    }
    //  }
    //}
  }
}
