.Cart {
  &__icon {
    width: 100%;
    height: 110px;
    display: flex;
    text-align: center;
    justify-content: center;
    img {
      height: 100%;
    }
  }
  &__desc {
    font-size: 20px;
    margin-top: 40px;
    color: #7c7c7c;
    text-align: center;
    margin-bottom: 20px;
  }
}
