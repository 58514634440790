.FooterMain {
  padding: 50px 0;
  &__inner {
    display: flex;
    flex-direction: column;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    & > li {
      width: 100%;
      padding-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      &.FooterMain__list__contact_info {
        width: 100%;

        p {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0.3rem;
        }
      }
    }
    button {
      padding: 0;
      border: none;
      color: #fff;
      font-size: 12px;
      text-align: left;
      font-weight: bold;
      margin-bottom: 20px;
      background: transparent;
      text-transform: uppercase;
    }
    p,
    a {
      font-size: 12px;
      color: #fff !important;
      margin-bottom: 10px !important;
      text-decoration: none !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .FooterMain {
    &__inner {
      display: block;
    }
    &__list {
      flex-direction: column;
      & > li {
        width: 100%;
        &.FooterMain__list__contact_info {
          width: 100%;
        }
      }
      button {
        text-align: left;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .FooterMain {
    &__inner {
      flex-direction: row;
    }
    &__list {
      flex-direction: row;
      & > li {
        width: 33.33%;
        &.FooterMain__list__contact_info {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .FooterMain {
    &__inner {
      flex-direction: row;
    }
    &__list {
      button {
        text-align: left;
      }
      & > li {
        margin-bottom: 20px;
        width: calc(25% - 30px);
        &.additional_item {
          margin-left: 0;
        }
        &.FooterMain__list__contact_info {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1024px) {
  .FooterMain {
    padding: 30px 0;
    &__inner {
      flex-direction: row;
    }
    &__list {
      button {
        text-align: left;
      }
      & > li {
        width: calc(25% - 30px);
        margin-bottom: 20px;
        &.additional_item {
          margin-left: 0;
        }
        &.FooterMain__list__contact_info {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .FooterMain {
    padding: 0;
    &__inner {
      flex-direction: column;
    }
    .FooterMain {
      &__half {
        margin-bottom: 20px;
        width: 100% !important;
      }
    }
    &__list {
      button {
        font-size: 12px;
        margin-bottom: 10px;
      }
      a {
        font-size: 12px;
      }
      & > li.FooterMain__list__contact_info {
        width: 100%;
      }
    }
    &__sub_list {
      li {
        line-height: 1em;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .FooterMain {
    padding: 25px 0;
    &__list {
      & > li.FooterMain__list__contact_info {
        width: 100%;
      }
    }
  }
}
