.ThemeSection {
  border: none;
  padding: 20px;
  margin-top: 20px;
  background: transparent;
  .ProductsLoader {
    .row {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ThemeSection {
    padding: 15px;
  }
}
