.GoToCheckoutButton {
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
  border-radius: 5px;
  background: #44a049;
  padding: 8px 25px 10px;
  text-transform: uppercase;
  color: white !important;
  text-decoration: none !important;
  &--disabled {
    opacity: 0.4;
  }
}
