.FormTitle {
  width: 100%;
  margin-bottom: 30px;
  h2 {
    color: black;
    font-size: 30px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .FormTitle {
    margin-bottom: 20px;
    h2 {
      font-size: 28px;
    }
  }
}
