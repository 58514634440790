.FormModal {
  width: 900px;
  padding: 0;
  margin: 50px auto;
  background: white;
  &--login {
    width: 400px;
  }
  &--sub {
    margin-top: -70px;
  }
  &--centered {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__error {
    font-size: 18px;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.4em;
  }
}

@media only screen and (max-width: 767px) {
  .FormModal {
    padding: 20px;
    margin: 40px 10px 20px;
    width: calc(100% - 20px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .FormModal {
    padding: 20px;
    margin: 40px 15px;
    width: calc(100% - 30px);
    &--login {
      width: 400px;
      margin: 40px auto;
    }
  }
}
