.Register {
  .loginLink {
    margin-top: -20px;
  }
  &--success {
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      font-size: 32px;
      text-align: center;
      margin-bottom: 15px;
      // background: url("../../assets/images/tick-icon.png") no-repeat 0;
      // background-position-x: 100%;
      // background-size: 30px;
    }
    h4 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 50px;
    }
  }
}
.error {
  text-align: center;
  color: #ff0000;
}
