@media only screen and (max-width: 767px) {
  .product_price {
    min-height: auto;
    margin-bottom: 10px;
    h4 {
      font-size: 15px;
    }
  }
  .Header__text {
    flex-direction: row;
  }
  // .Header__text p.vendi {
  //   display: none;
  // }
  .Header__text {
    width: 100%;
  }
  .Header__text p {
    max-width: 50%;
    margin-bottom: 0 !important;
  }
  .Header__text p.nr {
    font-size: 20px;
    margin-top: 3px;
    text-align: right;
  }
  html body .main_content {
    padding-top: 92px;
  }
  html body .ProductSingleMain__thumb img {
    min-width: 100%;
  }
  .ProductSingleMain .ProductAttributes__list {
    width: 100%;
  }
  html .ProductItem__title {
    font-size: 16px;
  }
  .FooterMain__inner {
    padding-top: 20px;
  }
  .FormModal {
    padding: 20px;
  }
}
