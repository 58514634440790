.Payment {
  &--loading {
    svg {
      width: 45%;
    }
  }
}

#pos_payment_type {
  display: none;
  min-width: 250px;
}
#other_payment:checked ~ #pos_payment_type {
  display: block;
}
