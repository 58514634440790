.NavigationButton {
  margin: 0 10px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  background: #ffd101;
  padding: 8px 25px 10px;
  text-transform: uppercase;
  color: black !important;
  text-decoration: none !important;
}
