.Order {
  width: 100%;
  padding: 20px;
  margin-bottom: 0;
  border-radius: 6px;
  display: inline-block;
  border: 1px solid #ddd;
  &:last-child {
    margin-bottom: 0;
  }
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__item {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      flex-direction: row;
      &:last-child {
        padding-right: 0;
        margin-bottom: 0;
        margin-left: auto;
      }
    }
  }
  &__info_title {
    font-size: 16px;
    margin-bottom: 0;
  }
  &__info_value {
    color: gray;
    font-size: 17px;
    margin-bottom: 0;
    padding-left: 5px;
  }
  &__info_link {
    font-size: 14px;
    &:after {
      content: "";
      width: 17px;
      height: 17px;
      float: right;
      margin-top: 4px;
      margin-left: 5px;
      background: url("../../../assets/images/arrow-more.svg") no-repeat center;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Order {
    margin-bottom: 20px;
  }
}
