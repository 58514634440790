html body .ProductSingleMain {
  padding: 0;
  display: flex;
  width: 100% !important;
  &__thumb {
    padding: 0;
    position: relative;
    img {
      min-width: 400px;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ProductSingleMain {
    padding: 0 !important;
    &__thumb {
      img {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}
html body .ProductSingleMain .ProductAttributes__title {
  font-weight: bold !important;
}
