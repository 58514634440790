.FooterSocial {
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 13px;

    li {
      padding: 10px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: white;
        text-decoration: none;
      }

      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
