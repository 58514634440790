.TopHeader {
  padding: 10px 0;
  background-color: #FF0000;
  &__hamburger {
    width: 30px;
    display: none;
    cursor: pointer;
    margin-left: auto;
    position: relative;
    span {
      width: 100%;
      height: 4px;
      display: block;
      margin-bottom: 4px;
      background: white;
      transition: all 0.3s ease-in-out;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &--active {
      span {
        &:first-child {
          margin-top: 7px;
          margin-bottom: 2px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          margin-top: -14px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 1240px) and (min-width: 768px) {
  .TopHeader {
    &__hamburger {
      display: block;
    }
    .Search {
      display: none;
    }
    .UserNavigation {
      display: flex;
      margin-left: 0 !important;
      margin-right: 5px;
      li {
        margin-right: 0;
        .login-link {
          display: none !important;
        }
        .divider {
          display: none;
        }
      }
      &__text {
        display: none;
      }
      .divider {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .TopHeader {
    &__hamburger {
      display: block;
    }
    .UserNavigation {
      display: flex;
      margin-left: 0 !important;
      margin-right: 5px;
      li {
        margin-right: 13px;
        .login-link {
          margin-top: 1px;
          margin-right: 0;
        }
        .divider {
          display: none;
        }
      }
      &__text {
        display: none;
      }
      &__logo {
        margin-right: 0;
      }
      .divider {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .TopHeader {
    &__hamburger {
      display: block;
    }
    .UserNavigation {
      display: flex;
      margin-left: 0 !important;
      margin-right: 5px;
      li {
        margin-right: 13px;
        .login-link {
          margin-top: 1px;
          margin-right: 0;
        }
        .divider {
          display: none;
        }
      }
      &__text {
        display: none;
      }
      &__logo {
        margin-right: 0;
      }
      .divider {
        display: none;
      }
    }
  }
}
