.MobileNavigation {
  width: 100%;
  display: none;
  &--active {
    display: flex;
  }
  .UserNavigation,
  .Search {
    display: block;
  }
  .UserNavigation {
    margin: 20px 0 !important;
    li {
      width: 100%;
      padding: 10px 0;
    }
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1240px){
  .MobileNavigation{
    display: block;
  }
}