.CartButtons {
  &__button {
    border: none;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    margin-right: 10px;
    border-radius: 30px;
    background: #fea500;
    text-transform: uppercase;
    color: white !important;
    text-decoration: none !important;
    &:last-child {
      margin-right: 0;
    }
    &--buy {
      background: #4ebd08;
    }
    &--buy_update {
      color: black !important;
      background: #fff8c1;
    }
  }
  &__cart {
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid #d8d8d8;
    text-decoration: none !important;
    img {
      width: 18px;
    }
    &:last-child {
      margin-right: 0;
    }
    &--buy {
      background: #44a049 url("../../assets/images/add_to_cart-white.png")
        no-repeat 9px 9px;
      background-size: 18px;
      padding: 7px 25px 7px 40px;
    }
    &--buy_update {
      color: black !important;
      background: #fff8c1 url("../../assets/images/add_to_cart.png") no-repeat
        9px 9px;
      background-size: 18px;
      padding: 7px 25px 7px 40px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .CartButtons {
    width: 100%;
    display: flex;
    margin-top: 10px;
    &__button {
      margin-right: 10px;
      &:last-child {
        text-align: center;
        margin-top: 15px;
      }
    }
  }
}
@media only screen and (max-width: 1240px) and (min-width: 768px) {
  .CartButtons {
    &__button {
      margin-right: 0;
      &:last-child {
        display: inline-block;
        margin-top: 10px;
        padding: 7px 35px;
      }
    }
  }
}
