.LoadMoreButton {
  width: 100%;
  margin: 20px 0;
  text-align: center;
  button {
    width: auto;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 18px 40px;
    background: #fd0b09;
    text-transform: uppercase;
    border: 2px solid #fd0b09;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #fd0b09;
      background: transparent;
    }
  }
}
